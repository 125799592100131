import React, { useState, useEffect } from "react";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import useStyles from "./styles";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { useTheme } from "@material-ui/styles";
// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import http from "../../helpers/http";
import Loading from "../../components/Loading";

export default function Dashboard(props) {

  const [loading, setLoading] = useState(false);

  const [accessCount, setAccessCount] = useState({});
  const [studentsToExpire, setStudentsToExpire] = useState({});
  const [studentsByCourse, setStudentsByCourse] = useState({});
  const [studentsByCourseFormatted, setStudentsByCourseFormatted] = useState([]);
  const [listConversationClass, setListConversationClass] = useState([]);

  useEffect(() => {
    loadDashboard();
  }, []);

  async function loadDashboard() {
    setLoading(true);
    const response = await http.get("api/admin/dashboard");
    const { data } = response.data;

    if (data) {
      setAccessCount(data.accessCount);
      setStudentsByCourse(data.studentsByCourse);
      setStudentsToExpire(data.studentsToExpire);
      setListConversationClass(data.conversationClass);

      var formatted = [];
      var colors = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.success.main];

      Object.keys(data.studentsByCourse).map((k) => (
        formatted.push({
          "course": k,
          "value": data.studentsByCourse[k],
          "color": colors[Math.floor(Math.random() * colors.length)]
        })
      ));

      setStudentsByCourseFormatted(formatted);
    }

    setLoading(false);
  }

  const classes = useStyles();
  var theme = useTheme();

  return (
    <>
      <PageTitle title="Dashboard" button="Atualizar" />
      <Grid container spacing={4} className={classes.gridAdmin}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget title="Acessos" upperTitle disableWidgetMenu>
            {/* <div>
                 <LineChart
                  width={55}
                  height={30}
                  data={[
                    { value: 10 },
                    { value: 15 },
                    { value: 10 },
                    { value: 17 },
                    { value: 18 },
                  ]}
                  margin={{ left: theme.spacing(2) }}
                >
                  <Line
                    type="natural"
                    dataKey="value"
                    stroke={theme.palette.success.main}
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </div> */}

            {loading ? <Loading /> :
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <Typography color="text" colorBrightness="secondary">
                    Hoje
                </Typography>
                  <Typography size="md">{accessCount?.today}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="text" colorBrightness="secondary">
                    Na Semana
                </Typography>
                  <Typography size="md">{accessCount?.week}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="text" colorBrightness="secondary">
                    No Mês
                </Typography>
                  <Typography size="md">{accessCount?.month}</Typography>
                </Grid>
                {/* <Grid item>
                  <Typography color="text" colorBrightness="secondary">
                    Cresc.
                </Typography>
                  <Typography size="md">25%</Typography>
                </Grid> */}
              </Grid>}
          </Widget>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget title="Alunos a Expirar" upperTitle disableWidgetMenu>
            {loading ? <Loading /> :
              Object.keys(studentsToExpire).map((k) => (
                <div styles={{ height: 200 }}>
                  <Typography size="md" color="text" colorBrightness="secondary">
                    {k} [{studentsToExpire[k]} / {studentsByCourse[k]}]
                  </Typography>
                  <LinearProgress variant="determinate" value={parseInt(studentsToExpire[k] / studentsByCourse[k] * 100)} />
                </div>
              ))
            }
          </Widget>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget
            title="Alunos por Lançamento"
            upperTitle
            disableWidgetMenu
            className={classes.card}
          >
            {loading ? <Loading /> :
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <ResponsiveContainer width="100%" height={144}>
                    <PieChart margin={{ left: theme.spacing(2) }}>
                      <Pie
                        data={studentsByCourseFormatted}
                        innerRadius={30}
                        outerRadius={45}
                        dataKey="value"
                      >
                        {studentsByCourseFormatted.map(({ color }, i) => (
                          <Cell
                            key={`cell-${i}`}
                            fill={color}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid item xs={7}>
                  <div>
                    {studentsByCourseFormatted.map(({ course, value, color }, index) => (
                      <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                        <Dot color={color} />
                        <Typography style={{ whiteSpace: "nowrap" }}>
                          {course}{" "}[{value}]
                        </Typography>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>}
          </Widget>
        </Grid>

        {/* {mock.bigStat.map((stat) => (
          <Grid item md={4} sm={6} xs={12} key={stat.product}>
            <BigStat {...stat} />
          </Grid>
        ))} */}

        <Grid item xs={12}>
          <Widget title="Lista de Salas x Ocupação" upperTitle disableWidgetMenu>
            {loading ? <Loading /> : <Table data={listConversationClass} />}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}