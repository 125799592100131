import {
  Box,
  Button,
  Card,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
//import { useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import http from "../../helpers/http";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 12,
  },
  idText: {
    fontSize: 12,
    fontWeight: "bold",
  },
  headerTitle: {
    marginLeft: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
  },
  buttonBottom: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      marginLeft: 0,
      "& > .MuiGrid-item": {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  },
  cardRow: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
}));

function Classrooms() {
  const classes = useStyles();
  const [datetime, setDatetime] = useState(moment);
  const [rooms, setRooms] = useState([]);
  const breadcrumbs = [
		{ title: "Home", path: "/dashboard" },
		{ title: "Salas de Encontro", path: "/classrooms" },
	  ];
  async function loadRooms() {
    await http
      .get(
        `api/admin/conversationClasses?return_type=collection&start_date=${moment().format(
          "YYYY-MM-DD 00:00:00",
        )}&end_date=${moment().format("YYYY-MM-DD 23:59:59")}`,
      )
      .then((response) => {
        const { data } = response.data;
        setRooms(data);
      })
      .catch((error) => {
        if (error.response) {
          error.response.data.errors
            ? Object.entries(error.response.data.errors).forEach(([_, value]) =>
                toast.error(value[0]),
              )
            : toast.error(error.response.data.message);
        }
        if (!error.response) {
          toast.error(
            "Ocorreu um erro em nosso servidor, por favor, tente novamente mais tarde!",
          );
        }
      })
      .finally(() => {});
  }
  useEffect(() => {
    loadRooms();
    const interval = setInterval(() => {
      setDatetime(moment);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const getReadableDate = (stringDate) => {
    var date = moment(stringDate);
    return date.format("DD/MM/YYYY HH:mm");
  };
  const getRegressiveCount = (stringDate) => {
    var date = moment(stringDate);
    var difference = date.diff(datetime);
    return moment(difference).format("HH:mm:ss");
  };

  if (rooms.length === 0){
    return(
      <Grid md={12} item>
      <Card style={{ padding: "20px 15px" }}>
        <p>
          Ops... não encontramos nenhuma Sala de encontro disponìvel.
        </p>
      </Card>
    </Grid>
    )
  }
  return (
    <>
      <PageTitle title="Salas de Encontro" breadcrumbs={breadcrumbs} />
      <Grid container xs={12} spacing={2}>
        {rooms.map((r) => (
          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <div className={classes.cardRow}>
                <Typography className={classes.idText}># {r.id}</Typography>
                <Typography className={classes.headerTitle}>
                  {r.title}
                </Typography>
                <Box mx={0.5} />
                {getRegressiveCount(r.schedule_time) > 0 && (
                  <Typography>
                    Começa em {getRegressiveCount(r.schedule_time)}h
                  </Typography>
                )}
              </div>
              <Box my={1} />
              <div>
                <Typography className={classes.title}>Data </Typography>
                <Typography>{getReadableDate(r.schedule_time)}</Typography>
              </div>
              <Box my={0.5} />
              <Box my={0.5} />
              <div>
                <Typography className={classes.title}>Alunos</Typography>
                {r.schedules.map((s) => (
                  <Typography>{s.student.user.name}</Typography>
                ))}
              </div>
              <Box my={1} />
              <Grid
                xs={12}
                container
                justify="space-between"
                spacing={2}
                className={classes.buttonBottom}
              >
                <Grid item md={6} xs={12}>
                  {r.materials.map((material) => {
                    return (
                      <Button
                        target="_blank"
                        component={Link}
                        href={`https://thefluency.s3.us-east-2.amazonaws.com//${material.file_url}`}
                        variant="contained"
                        color="secondary"
                        fullWidth
                      >
                        Material
                      </Button>
                    );
                  })}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Button
                    target="_blank"
                    omponent={Link}
                    href={r.url}
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Ir para o encontro
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Classrooms;
