import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions } from "@material-ui/core";
import Loading from "../Loading";
import { toast } from "react-toastify";
import http from "../../helpers/http";

export default function ChangePassword(props) {

	const { open, setOpen } = props;

	const [loading, setLoading] = useState(false);

	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

	const handleClose = () => {
		setOpen(false);
	}

	const checkFormValidity = () => {
		const currentPasswordInput = document.getElementById('currentPassword');
		const newPasswordInput = document.getElementById('newPassword');
		const newPasswordConfirmInput = document.getElementById('newPasswordConfirm');

		return currentPasswordInput.checkValidity() &&
			newPasswordInput.checkValidity() &&
			newPasswordConfirmInput.checkValidity();
	}

	async function changePassword() {
		setLoading(true);

		if (checkFormValidity()) {
			if (newPassword.length < 8)
				toast.error("A nova senha deve possuir pelo menos 8 caracteres");
			else if (newPassword != newPasswordConfirm)
				toast.error("As senhas informadas não coincidem");
			else {
				try {

					const data = {
						password: newPassword,
						password_confirmation: newPasswordConfirm,
						current_password: currentPassword
					}

					await http.post("/api/changePassword", data);
					toast.success("Senha alterada com sucesso!");
					handleClose();
				} catch (error) {
					if (error.response) {
						error.response.data.errors
							? Object.entries(error.response.data.errors).forEach(([_, value]) =>
								toast.error(value[0]),
							)
							: toast.error(error.response.data.data.message);
					}
					if (!error.response) {
						toast.error("error in server, try later");
					}
				}
			}
		}

		setLoading(false);
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Alterar Senha</DialogTitle>
			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<DialogContent>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="currentPassword"
						label="Senha Atual"
						type="password"
						autoFocus
						value={currentPassword}
						onChange={(e) => setCurrentPassword(e.target.value)}
					/>

					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="newPassword"
						label="Nova Senha"
						type="password"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
					/>

					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="newPasswordConfirm"
						label="Repetir Nova Senha"
						type="password"
						value={newPasswordConfirm}
						onChange={(e) => setNewPasswordConfirm(e.target.value)}
					/>
				</DialogContent>

				<DialogActions style={{ justifyContent: 'space-around' }}>
					<Button onClick={handleClose} color="secondary">
						VOLTAR
				</Button>
					{loading ? <Loading /> :
						<Button type="submit" onClick={changePassword} color="primary" autoFocus>
							ALTERAR
					</Button>}
				</DialogActions>
			</form>
		</Dialog>
	);
}