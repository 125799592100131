import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 140px)`,
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: `100vw`,
    },
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    [theme.breakpoints.down("sm")]: {
      width: `100vw`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}));
