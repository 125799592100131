import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  setCurrentLesson: ["lesson"],
  setCurrentModule: ["module", "indexLesson"],
  setCurrentIndexLesson: ["indexLesson"],
  setNextLesson: []
});

const INITIAL_STATE = {
  currentLesson: {},
  currentModule: [],
  currentIndexLesson: 0
};

export const setCurrentIndexLesson = (state = INITIAL_STATE, action) => {

  var indexLesson = action.indexLesson;
  var lesson = state.currentModule.lessons[indexLesson] ?? {};

  return {
    ...state,
    currentIndexLesson: indexLesson,
    currentLesson: lesson
  };

};

export const setCurrentModule = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentModule: action.module,
  };
};

export const setNextLesson = (state = INITIAL_STATE, action) => {

  var nextIndexLesson = state.currentIndexLesson + 1 < state.currentModule.lessons.length? state.currentIndexLesson + 1 : 0;
  var lesson = state.currentModule.lessons[nextIndexLesson] ?? {};

  return {
    ...state,
    currentLesson: lesson,
    currentIndexLesson: nextIndexLesson
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_INDEX_LESSON]: setCurrentIndexLesson,
  [Types.SET_CURRENT_MODULE]: setCurrentModule,
  [Types.SET_NEXT_LESSON]: setNextLesson
});
