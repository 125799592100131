import React from "react";
import { Redirect, Route } from "react-router-dom";
import { store } from "../../../store/";
function RouteWrapper({ component: Component, isPrivate, ...rest }) {
  const { signed } = store.getState().auth;
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }
  if (signed && !isPrivate) {
    return <Redirect to="/home" />;
  }
  return <Route {...rest} component={Component} />;
}
export default RouteWrapper;
