import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent,  Button, DialogActions } from "@material-ui/core";
//import Loading from "../Loading";
import UploadPic from '../UploadProfilePic/UploadPic';


export default function ChangeProfilePic(props) {

	  const { openModal, setOpenModal } = props;
    //const [loading, setLoading] = useState(false);

    const handleCloseModal = () => {
      setOpenModal(false);
    }

	return (
		
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        
        <DialogTitle id="alert-dialog-title">Alterar Foto do Perfil</DialogTitle>

          <DialogContent>
            <UploadPic />
          </DialogContent>

          <DialogActions style={{ justifyContent: 'space-around' }}>
              <Button onClick={handleCloseModal} color="secondary">
                  VOLTAR
              </Button>
              {/* {loading ? <Loading /> :
                <Button type="submit" color="primary" autoFocus>
                  Salvar
              </Button>} */}
          </DialogActions>
      </Dialog>
    )
}
