import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Drawer, IconButton, List } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

import useStyles from "./styles";

import SidebarLink from "./components/SidebarLink/SidebarLink";

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import {
  structureAdmin,
  structureTutor,
} from "./structure";

function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();

  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  const userModule = useSelector((state) => state.module.module);
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
    // eslint-disable-next-line
  }, []);

  const pathname = window.location.pathname;

  return pathname !== "/student-course" ? (
    <Drawer
      id={"sidebar"}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
        {userModule === 'Tutor' &&
          structureTutor.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        {userModule === 'Admin' &&
          structureAdmin.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
    </Drawer>
  ) : null;

  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    isSmallScreen && isPermanent ? setPermanent(false) : setPermanent(true);
  }
}

export default withRouter(Sidebar);
