import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loading() {
  return (
    <div style={{ display: "flex" }}>
      <CircularProgress style={{ marginLeft: "50%" }} />
    </div>
  );
}

export default Loading;
