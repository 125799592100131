import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dashboardCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  description: {
    paddingRight: 50,
    paddingLeft: 50,
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      paddingLeft: 0,
      textAlign: "center",
    },
  },
  cardGrid: {
    display: "flex",
    flexDirection: "column",
  },
  smallCard: {
    alignItems: "space-around",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  headerCard: {
    background: "#fff",
    padding: 5,
    borderBottom: "1px solid #eee",
  },
  headerTitle: {
    color: "#3c3c3c",
    fontSize: 20,
    fontWeight: 600,
    padding: theme.spacing(1),
  },
  contentCard: {
    marginTop: 10,
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  actionCard: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  alignButton: {
    alignSelf: "flex-end",
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 13,
    fontWeight: 900,
    textDecoration: "none !important",
    padding: "5px 10px",
    borderRadius: 13,
    background: "#F45527",
    transition: "all .3s ease",
    "&hover": {
      opacity: 0.8,
    },
  },
  alignButtonAvailable: {
    alignSelf: "flex-end",
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 13,
    fontWeight: 900,
    textDecoration: "none !important",
    padding: "5px 10px",
    borderRadius: 13,
    background: "#F45527",
    transition: "all .3s ease",
    marginTop: "8px",
    "&hover": {
      opacity: 0.8,
    },
  },
  gridAdmin: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  gridStudent: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: 30,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  titleCard: {
    display: "flex",
    justifyContent: "space-between",
  },
  greeting: {
    fontSize: 34,
  },
  content: {
    fontSize: 15,
    color: "#666 !important",
    textAlign: "justify",
  },
  contentAvailable: {
    fontSize: 15,
    color: "#666 !important",
    textAlign: "justify",
    marginTop: "10px",
    marginBottom: "10px",
  },
  materials: {
    paddingBottom: theme.spacing(2),
  },
  materialsTitle: {
    fontWeight: "bold",
  },
  materialLink: {
    textDecoration: "none",
  },
  liveLink: {
    marginLeft: "5px;",
    marginRight: "5px;",
    textDecoration: "none",
    color: "#F45527",
  },
  liveArrow: {
    marginLeft: "15px;",
    marginRight: "15px;",
    color: "#F45527",
  },
  liveDate: {
    float: "right",
  },
  liveItem: {
    paddingTop: "20px",
  },
}));
