import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { Grid, Card, Button, makeStyles } from "@material-ui/core";
import {
  Replay,
  UpdateOutlined,
} from "@material-ui/icons";

import ConversationImg from "../../assets/img/conversation.png";
import PageTitle from "../../components/PageTitle/PageTitle";
import http from "../../helpers/http";

const useStyles = makeStyles((theme) => ({
  img: { height: "auto", width: "100%", borderBottom: "5px solid #AD1457" },
  icon: {
    fontSize: 18,
    verticalAlign: "bottom",
    marginRight: 5,
  },
  iconButton: {
    fontSize: 22,
    verticalAlign: "bottom",
    marginRight: 5,
  },
  card: {
    padding: "0px 15px 10px",
  },
  cardButton: {
    display: "flex",
    padding: "10px 15px 10px",
    justifyContent: "center",
  },
  title: {
    color: "#3C3C41",
    fontSize: 18,
    marginBottom: 5,
  },
  text: {
    color: "#666",
  },
}));

const ClassroomsAdminReplay = (props) => {
  const classes = useStyles();

  const [rooms, setRooms] = useState();

  const breadcrumbs = [
    { title: "Home", path: "/dashboard" },
    { title: "Gravações", path: "/classrooms-admin-replay" },
  ];

  const loadRooms = async () => {
    await http
      .get(`api/admin/conversationClasses`, {
        params: {
          start_date: moment().subtract(15, "days").format("Y-MM-DD"),
          end_date: moment().format("Y-MM-DD"),
          per_page: 100,
        },
      })
      .then((response) => {
        const { data } = response.data.data;
        setRooms(data);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(
          "Ocorreu um erro com o servidor, por favor, tente novamente mais tarde!",
        );
      });
  };

  useEffect(() => {
    loadRooms();
  }, []);

  return (
    <>
      <PageTitle title="Gravações" breadcrumbs={breadcrumbs} />

      <Grid container spacing="2">
        {!!rooms && !!rooms.length ? (
          rooms.map((room) => {
            if(room && room.zoom_recording_url) {
              return (
                <Grid item md={3} sm={12} xs={12}>
                  <Card>
                    <img
                      src={ConversationImg}
                      alt="conversation"
                      className={classes.img}
                    />
                    <div className={classes.card}>
                      <p className={classes.title}>
                        <b>
                          {room
                            ? room.title
                            : `Aula de Conversação - ${moment(
                                room.schedule_time,
                              ).format("DD/MM/Y HH:mm")}`}
                        </b>
                      </p>
                      <small className={classes.text}>
                        <UpdateOutlined className={classes.icon} />
                        Gravada em:{" "}
                        <b>{moment(room.schedule_time).format("DD/MM/Y HH:mm")}</b>
                      </small>
                    </div>
                    <div className={classes.cardButton}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        href={room.zoom_recording_url}
                        target="_blank"
                      >
                        
                        <Replay className={classes.iconButton} />
                        <b>Assistir Novamente</b>
                      </Button>
                    </div>
                  </Card>
                </Grid>
              );
            }
            return (
               <Grid item md={3} sm={12} xs={12}>
                <Card style={{ padding: "20px 15px" }}>
                  <p>
                    Ops... aula encontrada não possui nenhuma gravação disponível.
                  </p>
                </Card>
              </Grid>
            )
          })
        ) : (
          <Grid md={12} item>
            <Card style={{ padding: "20px 15px" }}>
              <p>
                Ops... não encontramos nenhuma aula disponível para assistir
                novamente.
              </p>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ClassroomsAdminReplay;
