import React from "react";
import BadgeModulo from './components/BadgeModulo/BadgeModulo';
import { HomeSharp as HomeSharpIcon } from "@material-ui/icons";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import GridOnIcon from "@material-ui/icons/GridOn";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InfoIcon from "@material-ui/icons/Info";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HistoryIcon from "@material-ui/icons/History";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import VideoCallOutlinedIcon from '@material-ui/icons/VideoCallOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import DvrOutlinedIcon from '@material-ui/icons/DvrOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";
import SortOutlinedIcon from "@material-ui/icons/SortOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import VoiceChat from "@material-ui/icons/VoiceChat";

const structureAdmin = [
  { id: 1, label: "Home", link: "/dashboard", icon: <HomeSharpIcon /> },
  {
    id: 2,
    label: "Alunos",
    link: "/students",
    icon: <PeopleAltOutlinedIcon />,
  },
  { id: 3, type: "divider" },
  { id: 4, type: "ListSubheader", subHeader: "ADMINISTRAÇÃO" },
  { id: 5, label: "Módulos", link: "/modules", icon: <BorderAllIcon /> },
  { id: 6, label: "Banners", link: "/banners", icon: <CategoryOutlinedIcon />},
  {
    id: 7,
    label: 'Courses',
    link: '/courses',
    icon: <DvrOutlinedIcon />,
  },
  {
    id: 8,
    label: "Materiais",
    link: "/materials",
    icon: <FileCopyOutlinedIcon />,
  },
  { id: 9, type: "divider" },
  { id: 10, type: "ListSubheader", subHeader: "TABELAS" },
  {
    id: 11,
    label: 'Pathways',
    link: '/pathways',
    icon: <FolderOpenOutlinedIcon />,
  },
  {
    id: 12,
    label: 'Videos',
    link: '/videos',
    icon: <VideoCallOutlinedIcon />,
  },
  { id: 13, label: "Tags", link: "/tags", icon: <BookmarksOutlinedIcon /> },

  { id: 14, label: "Lives", link: "/lives", icon: <VideocamOutlinedIcon /> },
  { id: 15, label: "Ebooks", link: "/ebooks", icon: <SchoolOutlinedIcon /> },

  { id: 16, label: "Linguagens", link: "/languages", icon: <SchoolOutlinedIcon /> },

  { id: 3, type: "divider" },
  { id: 4, type: "ListSubheader", subHeader: "CONTEÚDOS" },
  { id: 5, label: "GroupClass", link: "/groupclass", icon: <FileCopyOutlinedIcon /> },
  { id: 6, label: "MasterClass", link: "/masterclass", icon: <FileCopyOutlinedIcon />},

  { id: 16, type: "divider" },
  { id: 17, type: "ListSubheader", subHeader: "CONVERSAÇÃO" },
  {
    id: 18,
    label: "Encontros",
    link: "/schedules",
    icon: <EventAvailableOutlinedIcon />,
  },
  /*{ id: 19, label: "Agenda", link: "/schedule", icon: <DateRangeIcon /> },
  {
    id: 20,
    label: "Salas de Encontro",
    link: "/classrooms",
    icon: <ApartmentOutlinedIcon />,
  },
  {
    id: 21,
    label: "Gravações",
    link: "/classrooms-admin-replay",
    icon: <VoiceChat />,
  },*/
];



const structureTutor = [
  {
    id: 1,
    label: 'Home',
    link: '/dashboard-tutor',
    icon: <HomeSharpIcon />,
  },
  {
    id: 2,
    label: 'Schedules',
    link: '/classrooms-tutor',
    icon:  <DateRangeIcon />,
  },
  {
    id: 6,
    label: 'Class History',
    link: '/historic',
    icon: <HistoryIcon />,
  },
];



export { structureAdmin, structureTutor };
