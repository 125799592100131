import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../../assets/img/the-fluency-logo-nova.png";
import logoOFTB from "../../assets/img/the-fluency-logo-nova.png";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

export default function TermOfUse() {
  const useStyles = makeStyles((theme) => ({
    Container: {
      margin: "0 auto",
      padding: 10,
    },
    Image: {
      // display: "grid",
      // gridTemplateColumns: "55% 45%",
    },
    Containerlogo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    logo: {
      width: "15rem",
    },
    logoOFTB: {
      width: "60px",
      height: "60px",
    },
    bold: {
      fontWeight: "bold",
    },
    footer: {
      borderTopWidth: 1,
      borderTopColor: "#969696",
      borderTopStyle: "solid",
      padding: 20,
    },
    listaOrd: {
      listStyleType: "lower-alpha",
    },
    goback: {
      color: "#575757",
    },
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} lg={10} className={classes.Container}>
      <div className={classes.Containerlogo}>
        <a href="/" className={classes.goback}>
          <NavigateBeforeIcon fontSize="large" />
        </a>
        <a href="/">
          <img src={logo} alt="Logo En France" className={classes.logo} />
        </a>

        <a href="/">
          <img src={logoOFTB} alt="Logo OTFB" className={classes.logoOFTB} />
        </a>
      </div>
      <div>
        <Typography
          variant="h6"
          paragraph
          align="center"
          className={classes.bold}
        >
          TERMOS DE USO E POLÍTICA DE PRIVACIDADE
          <br /> CONTRATO DE PRESTAÇÃO DE SERVIÇOS: CURSO DE IDIOMA - FRANCÊS
        </Typography>
        <Typography variant="h6" paragraph className={classes.bold}>
          Nós da EN FRANCE CURSO DE IDIOMA FRANCES SPE LTDA, CNPJ:
          36.864.702/0001-60 temos o prazer de oferecer-lhe acesso COMPLETO aos
          conteúdos didáticos (como definido abaixo), sujeito aos termos e
          condições ("Termo de Acesso") e à Política de Privacidade aplicável.
        </Typography>
        <Typography paragraph>
          Os presentes Termos e Condições de Uso e Política de Privacidade
          destinam-se a definir os direitos e obrigações da EN FRANCE e dos seus
          Clientes no âmbito da utilização do CURSO COMPLETO e demais serviços
          fornecidos como complementação dele.
        </Typography>
        <Typography paragraph>
          Ao acessar o conteúdo didático completo, você expressa sua
          concordância e consentimento com o Termo de Acesso e Política de
          Privacidade.
        </Typography>
        <Typography paragraph>
          Caso não esteja de acordo, não acesse os conteúdos didáticos.
        </Typography>
        <Typography paragraph>
          Em caso de dúvidas acesse o conteúdo de Ajuda disponibilizado em nosso
          site.
        </Typography>
        <Typography paragraph>
          1. Características Gerais dos Termos de Serviço
        </Typography>
        <Typography paragraph>
          1.1. O EN FRANCE oferece ao usuário contratante ferramentas didáticas
          para aprender e/ou aprimorar conhecimento da língua francesa.
        </Typography>
        <Typography variant="h6" paragraph className={classes.bold}>
          2. Permissão para acessar o conteúdo exclusivo
        </Typography>
        <Typography paragraph>
          2.1. Ao aceitar o Termo de Acesso, o Usuário tem garantido o direito
          não exclusivo, intransferível, não sublicenciável e limitado de
          acessar o site e utilizar TODOS os conteúdos didáticos disponíveis e
          referentes ao curso adquirido.
        </Typography>
        <Typography paragraph>
          2.2. Os direitos não garantidos expressamente no Termo de Acesso estão
          reservados ao EN FRANCE.
        </Typography>
        <Typography paragraph>
          2.3. O acesso ao curso por completo é garantido por meio de conta
          cadastrada para seu exclusivo uso pessoal e não comercial. Ninguém
          além do Usuário cadastrado poderá usufruir dos conteúdos didáticos em
          seu nome.
        </Typography>
        <Typography paragraph>
          2.4. O Usuário não tem o direito de copiar ou reproduzir, no todo ou
          em parte, o conteúdo didático disponibilizado no EN FRANCE.
        </Typography>
        <Typography paragraph>
          2.5. O Usuário aceita que, em todas as circunstâncias, os seus
          direitos relacionados ao conteúdo didático disponibilizado pelo EN
          FRANCE são limitados por direitos autorais e ou leis de propriedade
          intelectual.
        </Typography>
        <Typography paragraph>
          2.6. O Usuário poderá ter acesso aos conteúdos didáticos
          disponibilizados pelo EN FRANCE, mediante cadastro de dados (nome,
          e-mail, telefone, CPF e cartão de crédito), seguido da necessidade de
          clicar em "Assine Agora". Após esse prazo, será automaticamente
          efetivado seu acesso ininterrupto pelo prazo de dois anos, ao preço da
          tabela vigente, facultado ao Usuário o parcelamento do preço conforme
          a sua escolha dentre as opções dispostas na Cláusula 3.2 do presente
          termo.
        </Typography>
        <Typography variant="h6" paragraph className={classes.bold}>
          3. Descrição do serviço
        </Typography>
        <Typography paragraph>
          3.1. Ao efetuar a compra do curso, o EN FRANCE garantirá ao Usuário
          acesso aos seguintes conteúdos:
          <ul>
            <li>
              40 módulos liberados semanalmente a partir do dia da compra
              (compostos de até 3 vídeo aulas + material de apoio).
            </li>
            <li>
              Encontros virtuais de conversação em grupo com professores da
              equipe EN FRANCE dispostos da seguinte forma:
              <ul>
                <li>Ao adquirir o curso EN FRANCE, o aluno recebe créditos.</li>
                <li>01 (um) crédito é referente a uma aula por semana. </li>
                <li>
                  Cada crédito deve ser usado dentro do prazo de (07)sete dias,
                  vencendo no 8º dia.
                </li>
                <li>Créditos não cumulativos.</li>
                <li>
                  Quantidade de créditos/encontros: 48 (quarenta e oito)
                  créditos/ano 96 (noventa e seis) créditos por dois anos de
                  acesso
                </li>
                <li>Duração dos encontros : 01 (uma) hora por semana</li>
                <li>
                  Número de participantes: máximo 15 (quinze) alunos por
                  encontro.
                </li>
                <li>
                  Cancelamento com reaproveitamento do crédito deverá ser feito
                  com 24 horas de antecedência.
                </li>
              </ul>
            </li>
            <li>
              O programa tem duração média de 10 meses. O acesso ao curso é de 2
              anos a partir do dia da compra.
            </li>
            <li>
              O aluno poderá enviar dúvidas por e-mail durante todo período de
              acesso (2 anos).
            </li>
          </ul>
        </Typography>
        <Typography paragraph>
          3.2. O acesso aos conteúdos didáticos se dará pelo prazo de 02 (dois)
          anos mediante o pagamento do valor de tabela em vigor na data do
          aceite, da seguinte forma:
        </Typography>
        <Typography paragraph>
          Pagamento Parcelado via Cartão de Crédito: O Usuário que optar por
          essa modalidade, registrará seu cartão de crédito, do qual o preço
          pelo acesso será cobrado em parcelas fixas, iguais e sucessivas.
        </Typography>
        <Typography paragraph>
          3.3. Para garantir o acesso, o usuário deverá preencher seus dados na
          página da EN FRANCE (nome, e-mail e telefone) e, em seguida, informar
          os dados do cartão de crédito.
        </Typography>
        <Typography paragraph>
          3.3.1. O usuário que desejar descontinuar o acesso, deverá solicitar o
          cancelamento através do{" "}
          <a href="https://refund.hotmart.com/?utm_source=helpcenter&utm_campaign=article_209034787">
            https://refund.hotmart.com/?utm_source=helpcenter&utm_campaign=article_209034787
          </a>
          , observado o item 10.4., do presente termo.
        </Typography>
        <Typography paragraph>
          3.3.2. O usuário que não solicitar o cancelamento conforme descrito
          acima, terá a cobrança efetuada, automaticamente.
        </Typography>
        <Typography variant="h6" paragraph className={classes.bold}>
          Regras e Políticas de Privacidade do EN FRANCE
        </Typography>
        <Typography paragraph>
          4.1. Ao acessar o EN FRANCE, o Usuário estará sujeito a todas as
          regras no presente termo, ficando autorizado ao site publicar
          diretrizes modificando ou adicionando novas regras.
        </Typography>
        <Typography paragraph>
          4.2. As diretrizes e regras são parte integrante deste Termo de
          Acesso, sendo aplicáveis a todos aqueles que acessam o EN FRANCE.
        </Typography>
        <Typography paragraph>
          4.3. O EN FRANCE monitora as atividades em sua plataforma. Sendo
          assim, uma identificação de sistema exclusiva pode ser criada, a fim
          de garantir segurança e evitar fraudes.
        </Typography>
        <Typography paragraph>
          4.4. O contratante deverá ter, no mínimo, 18 anos de idade para
          concordar e aceitar o Termo de Acesso em seu próprio nome. A hipótese
          de contratante ter menos de 18 anos de idade, os pais ou
          representantes legais devem aceitar o Termo de Acesso e inscrever-se
          no site, utilizando cadastro e nome próprio.
        </Typography>
        <Typography paragraph>
          4.5. O Usuário concorda que não utilizará o EN FRANCE para exibir,
          tornar disponível ou transmitir quaisquer conteúdos ou materiais:
          <ol className={classes.listaOrd}>
            <li>
              Ilegais, ameaçadores, obscenos, de natureza spam, invasivas de
              privacidade ou difamatórias;
            </li>
            <li>
              Que infrinjam e ou violem quaisquer direitos autorais, patentes,
              segredos comerciais, marcas comerciais, direito de privacidade ou
              publicidade, outros direitos de propriedade intelectual e,
              quaisquer outras leis aplicáveis;
            </li>
            <li> Que prejudiquem de qualquer forma menores;</li>
            <li> Campanha política;</li>
            <li>
              Vírus de software ou outros códigos de programação projetados para
              interferir na funcionalidade de quaisquer sistemas de computador
              ou redes de internet, e-mail, etc.
            </li>
          </ol>
        </Typography>
        <Typography paragraph>
          5.6. Os Usuários devem ter uma postura adequada e respeitosa ao
          acessar o conteúdo disponibilizado no EN FRANCE, tendo máxima atenção
          aos encontros virtuais- onde TODA ATITUDE CONSIDERADA DESRESPEITOSA,
          INCONVENIENTE/IMPERTINENTE, DE QUALQUER FORMA LESIVA OU CRIMINOSA
          estará sujeita à sanção prevista no item 5.7 do presente TERMO, bem
          como à eventuais implicações cíveis e criminais.
        </Typography>
        <Typography paragraph>
          5.7. Qualquer violação ao disposto no Termo de Acesso garantirá ao EN
          FRANCE o direito de adotar as medidas que julgar necessárias, podendo
          vedar o acesso do Usuário sem direito a reembolso.
        </Typography>
        <Typography paragraph>
          5.8. O Usuário não poderá fornecer um e-mail falso ou, qualquer outra
          informação que possa enganar o EN FRANCE ou os demais Usuários em
          relação à sua identidade, conteúdo da mensagem ou origem dela.
        </Typography>
        <Typography paragraph>
          5.9. O acesso incluirá componentes de segurança sendo aplicadas regras
          e políticas especiais. Você não deve tentar ou incentivar terceiros a
          driblar, reverter a engenharia, decodificar, descompilar, desmontar,
          fraudar ou interferir de qualquer forma com aspectos do acesso.
        </Typography>
        <Typography paragraph>
          5.10. O contratante não pode distribuir, modificar, intercambiar,
          vender, revender ou retransmitir qualquer parte do acesso, para
          qualquer pessoa ou finalidade empresarial, comercial ou pública.
        </Typography>
        <Typography paragraph>
          5.11. Além das formas descritas neste Termo de Acesso, o Usuário
          concorda em não transmitir o conteúdo do EN FRANCE de qualquer outra
          maneira, contanto que autorizada por ele.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          6. Restrição de uso relacionada à conta do EN FRANCE
        </Typography>
        <Typography paragraph>
          6.1. Compra e acesso ao curso, bem como às contas do EN FRANCE, são
          pessoais e intransferíveis.
        </Typography>
        <Typography paragraph>
          6.2. Você concorda que não autorizará que outras pessoas utilizem sua
          conta e concorda que irá manter a confidencialidade e segurança de
          suas informações de registro.
        </Typography>
        <Typography paragraph>
          6.3. Você concorda em notificar o EN FRANCE imediatamente após
          verificar ou desconfiar que sua conta foi utilizada por outra pessoa.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          7. Restrição de uso relacionada ao conteúdo do EN FRANCE
        </Typography>
        <Typography paragraph>
          7.1. Os direitos previstos neste Termo de Acesso, não garantem nenhum
          dos seguintes direitos de:
          <ol className={classes.listaOrd}>
            <li>
              Entrar em acordos com qualquer sistema de transmissão gerador de
              lucros (terrestre, satélite, cabo e/ou outros canais de
              distribuição);
            </li>
            <li>
              Distribuir a terceiros o conteúdo didático deste produto em outros
              sistemas de distribuição de conteúdo (aplicativos de áudio pago ou
              áudio sob demanda ou similares) ou em mídias físicas (e-book
              impresso ou digitalizado, Compact Disc, Digital Versatile Disc,
              chips semicondutores, discos duros, cartões de memória e
              similares);
            </li>
            <li>
              Reprodução ou uso promocional do conteúdo didático do EN FRANCE.
            </li>
            <li>
              Distribuir ou redistribuir o conteúdo didático do EN FRANCE em
              aplicativos de streaming (via Internet, intranets e/ou outras
              redes) ou mesmo e-book impresso ou venda de link de acesso ao
              mesmo;
            </li>
            <li>
              Uso comercial, de vendas, de revenda, de reprodução, distribuição
              ou promocionais do conteúdo didático do EN FRANCE.
            </li>
          </ol>
        </Typography>
        <Typography paragraph>
          7.2. Caso seja autorizada a posse ou controle de conteúdo didático do
          EN FRANCE ao Usuário, será responsabilidade do contratante não perder,
          danificar ou destruir.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          8. Faturamento
        </Typography>
        <Typography paragraph>
          8.1. Os contratantes devem concordar com o Termo de Acesso e autorizar
          o pagamento, de acordo com a opção escolhida.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          9. Política de reembolso
        </Typography>
        <Typography paragraph>
          9.1. Nenhuma despesa é reembolsável. Ao adquirir o acesso, o Usuário
          poderá usufruí-lo ao longo do período contratado.
        </Typography>

        {/* ---------------------------------------------------------------------------------- */}

        <Typography paragraph variant="h6" className={classes.bold}>
          10. Rescisão
        </Typography>
        <Typography paragraph>
          10.1. O EN FRANCE se reserva ao direito de rescindir a sua conta e
          impedir o seu acesso a qualquer tempo, caso seja constatada qualquer
          violação ao Termo de Acesso ou Política de Privacidade.
        </Typography>
        <Typography paragraph>
          10.2. Caso ocorra a possibilidade acima descrita, nenhuma taxa ou
          despesa será reembolsável.
        </Typography>
        <Typography paragraph>
          10.3. Para cancelar assinatura, o contratante deverá enviar sua
          solicitação de cancelamento via email e aguardar o retorno da área de
          atendimento para finalizar o procedimento.
        </Typography>
        <Typography paragraph>
          10.4. Usuário poderá requerer, no prazo de 14 (quatorze) dias da
          assinatura do acesso ao conteúdo didático, mediante envio de
          solicitação via e-mail, o cancelamento com devolução integral dos
          valores pagos.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          11. Pagamento e Transações no cartão de crédito
        </Typography>
        <Typography paragraph>
          11.1. Poderá ser solicitado, durante o processo de inscrição, a
          fornecer as informações do seu cartão de crédito.
        </Typography>
        <Typography paragraph>
          11.2. Como Usuário do acesso, o contratante concorda que sejam
          cobrados no seu cartão de crédito todas as taxas e impostos sobre
          vendas e quaisquer outras despesas nas quais possa incorrer
          relacionadas ao uso do EN FRANCE.
        </Typography>
        <Typography paragraph>
          11.3. Todas as despesas serão faturadas no cartão de crédito indicado,
          de acordo com a forma de pagamento escolhida em nosso site. Taxas ou
          encargos bancários decorrentes da contratação aqui tratada serão de
          sua responsabilidade.
        </Typography>
        <Typography paragraph>
          11.4. Decorrido o período contratado, o acesso ao conteúdo não será
          renovado automaticamente.
        </Typography>
        <Typography paragraph>
          11.5. Alterações acerca de faturamento deverão ser atualizadas junto
          ao nosso banco de dados.
        </Typography>
        <Typography paragraph>
          11.6. Ao oferecer as informações do cartão de crédito, o contratante
          reconhece e concorda que tais informações serão mantidas em no bancos
          de dados da EN FRANCE, até o término da relação contratual.
        </Typography>
        <Typography paragraph>
          11.7. Ao oferecer informações do cartão de crédito ao EN FRANCE, o
          contratante concorda que consultará os termos e condições que são
          impostos pelo emissor do seu cartão de crédito para requisitos e
          limitações de notificação sobre a sua responsabilidade em caso de
          perda, roubo ou uso não autorizado do seu cartão, bem como informações
          relacionadas ao limite de crédito e de uso do mesmo.
        </Typography>
        <Typography paragraph>
          11.8. O contratante concorda que será o único responsável pelo
          pagamento de todas as quantias faturadas. Concorda que não fornecerá
          nenhuma informação de cartão de crédito que não esteja em seu nome.
        </Typography>
        <Typography paragraph>
          11.9. O EN FRANCE não se responsabiliza em caso pagamento com cartão
          de crédito/débito pelo usuário que não seja titular do mesmo.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          12. Alteração de Valores
        </Typography>
        <Typography paragraph>
          12.1. Todos os preços listados estão sujeitos à alteração por parte do
          EN FRANCE a qualquer momento, sem que haja a necessidade de prévia
          notificação.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          13. Privacidade
        </Typography>
        <Typography paragraph>
          13.1. Ao registrar-se no EN FRANCE, O CONTRATANTE consente a coleta e
          processamento de todas as informações relacionadas ao uso dos acessos,
          incluindo as fornecidas para esse registro.
        </Typography>
        <Typography paragraph>
          13.2. Tais informações estão, igualmente, sujeitas à Política de
          Privacidade do EN FRANCE.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          14. Qualidade da Informação
        </Typography>
        <Typography paragraph>
          14.1. O CONTRATANTE declara que todas as informações fornecidas ao EN
          FRANCE são corretas, atuais e completas e, compromete-se a
          atualizá-las sempre que houver alteração.
        </Typography>
        <Typography paragraph>
          14.2. O contratante declara que as informações fornecidas ao EN FRANCE
          não violam nenhuma lei ou regulamento, nem infringem direitos de
          terceiros.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          15. Risco de uso
        </Typography>
        <Typography paragraph>
          15.2. O Usuário contratante está ciente que é responsável pela
          segurança de sua navegação, sendo assim, concorda expressamente que o
          EN FRANCE não poderá ser responsabilizado por quaisquer danos ou vírus
          que possam danificar o seu equipamento ou outras propriedades em
          decorrência de seu acesso, uso, download ou navegação nas plataformas
          disponíveis.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          16. Links e RSS Feeds
        </Typography>
        <Typography paragraph>
          16.1. O EN FRANCE não se responsabiliza pelo conteúdo disponível de
          quaisquer RSS Feeds ou sites com links para o EN FRANCE ou, sites de
          terceiros com link para o EN FRANCE.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          17. Alteração do Termo de Acesso
        </Typography>
        <Typography paragraph>
          17.1. O EN FRANCE se reserva ao direito de alterar o presente Termo de
          Acesso, a seu critério, podendo alterar, adicionar, modificar ou
          remover partes desse Termo de Acesso.
        </Typography>
        <Typography paragraph>
          17.2. É obrigatório ao Usuário contratante, periodicamente,
          inteirar-se do Termo de Acesso e Política de Privacidade, a fim de
          acompanhar e opinar acerca das modificações. A continuação do uso de
          sua conta no EN FRANCE constituirá a sua aceitação às alterações e ao
          novo Termo de Acesso.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          18. Alteração do conteúdo
        </Typography>
        <Typography paragraph>
          18.1. O EN FRANCE pode adicionar, interromper, alterar, remover ou
          suspender qualquer conteúdo didático, a seu critério, visando sempre
          obter o melhor desempenho de seus Usuários, incluindo funções, preços,
          especificações de produtos ou áreas de pesquisa.
        </Typography>
        <Typography paragraph>
          18.2. Alguns conteúdos poderão ser removidos do EN FRANCE, sem
          notificação prévia aos Usuários.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          19. Propriedade intelectual
        </Typography>
        <Typography paragraph>
          19.1. O EN FRANCE contém segredos comerciais, invenções patenteadas e
          ou patentes, bem como outros materiais de propriedade intelectual do
          mesmo e seus licenciadores. Todos os direitos e propriedade do
          conteúdo são de posse do EN FRANCE. Você concorda em manter essas
          informações que não são publicamente conhecidas confidenciais para
          você mesmo, não podendo transferir a terceiros sem prévio
          consentimento do EN FRANCE.
        </Typography>
        <Typography paragraph>
          19.2. É proibida qualquer tentativa de desmontar, reconfigurar,
          desconfigurar ou fazer engenharia reversa do produto principal ou do
          conteúdo nele disponibilizado, bem como de qualquer dos conteúdos dele
          advindos.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          20. Direitos autorais
        </Typography>
        <Typography paragraph>
          20.1. O conteúdo disponibilizado no EN FRANCE é protegido pelas leis e
          tratados de direitos autorais aplicáveis.
        </Typography>
        <Typography paragraph>
          20.2. O contratante não poderá reproduzir, bem como copiar para si,
          sem prévia autorização do EN FRANCE, qualquer conteúdo, seja ele no
          todo ou em parte, exceto conforme previsto neste Termo de Acesso.
        </Typography>
        <Typography paragraph>
          20.3. O contratante concorda em cumprir com todas as leis de proteção
          dos direitos autorais em conexão com o seu uso do EN FRANCE e do
          conteúdo nele disponibilizado
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          21. Marcas comerciais
        </Typography>
        <Typography paragraph>
          É vedado o uso de qualquer marca comercial encontrada no EN FRANCE,
          ficando vedada sua cópia ou exibição sem o consentimento prévio do
          detentor dos direitos da referida marca comercial.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          22. O direito do EN FRANCE sobre seu conteúdo
        </Typography>
        <Typography paragraph>
          22.1. Ao submeter qualquer conteúdo ao EN FRANCE, o contratante
          declara que detém todos os direitos sobre tal conteúdo, não
          infringindo direito de terceiros, bem como não sendo ofensivo aos
          demais Usuários e ao EN FRANCE.
        </Typography>
        <Typography paragraph>
          22.2. O contratante concorda que o EN FRANCE poderá utilizar qualquer
          conteúdo submetido por você da maneira que julgar adequado, podendo
          repassá-los a parceiros, respeitando sempre os direitos dos Usuários,
          com o objetivo de promover o EN FRANCE.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          23. Violação de direitos de propriedade intelectual
        </Typography>
        <Typography paragraph>
          23.1. O acesso poderá ser suspenso em caso de notificação de qualquer
          infração aos direitos do EN FRANCE, parceiros ou Usuários, sem
          notificação prévia. Caso isso ocorra, EN FRANCE não será responsável
          pela devolução de qualquer valor pago anteriormente.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          24. Medidas Judiciais
        </Typography>
        <Typography paragraph>
          24.1. O EN FRANCE poderá adotar medidas judiciais caso seja apurado
          fraude, dano ou prejuízo aos demais Usuários ou ao EN FRANCE, sendo, o
          Usuário contratante, responsável pela reparação de eventual dano.
        </Typography>
        <Typography paragraph>
          24.2. O CONTRATANTE concorda em indenizar o EN FRANCE em caso de danos
          causados pelo seu uso fora do previsto neste Termo de Acesso.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          25. Limitação e Isenção de responsabilidade
        </Typography>
        <Typography paragraph>
          25.1. O contratante concorda que usará o acesso por sua conta em
          risco. Nenhuma informação oral ou escrita criará garantias de qualquer
          tipo relacionadas ao EN FRANCE.
        </Typography>
        <Typography paragraph>
          25.2. O EN FRANCE não garante que o equipamento do usuário contratante
          é compatível com todo o conteúdo disponibilizado. Cabendo ao usuário
          contratante manter atualizados os programas e plug-ins instalados em
          seus equipamentos.
        </Typography>
        <Typography paragraph>
          25.3. O EN FRANCE poderá conter opiniões que representam o ponto de
          vista de outros Usuários. Tais opiniões não poderão ser atribuídas ao
          EN FRANCE sem o seu consentimento.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          26. Incorporação e aquisição da empresa
        </Typography>
        <Typography paragraph>
          26.1. Ao utilizar o EN FRANCE, o contratante concorda que a empresa ou
          uma de suas empresas poderá, no momento de uma fusão, aquisição ou
          venda do acesso, ou todos ou substancialmente os ativos da empresa,
          ceder os seus direitos e obrigações quanto ao acesso a um proprietário
          ou operador subsequente aplicável. No caso de uma fusão, aquisição ou
          venda, o seu uso continuado do acesso significa a sua concordância em
          limitar-se pelo Termo de Acesso e Política de Privacidade do
          proprietário ou operador subsequente do acesso.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          27. Do direito ao uso de imagem
        </Typography>
        <Typography paragraph>
          O usuário contratante AUTORIZA, a título gratuito, o uso de sua imagem
          em todo território nacional, por meio de todo e qualquer material
          impresso e/ou virtual, como sites, redes sociais, reportagens, vídeos
          institucionais, propaganda, outdoors, desenvolvidos pela EN FRANCE.
        </Typography>
        <Typography paragraph>
          Ciente do não recebimento de cachê, honorários, salário, ou pagamento
          de qualquer espécie, referente a confecção e veiculação de vídeo, sem
          que nada haja a ser reclamado a título de direitos conexos à imagem,
          indenizações ou a qualquer outro.
        </Typography>
        <Typography paragraph>
          O contratante, responsável legal por usuário menor de idade, AUTORIZA
          a título gratuito, o uso de sua imagem em todo território nacional,
          por meio de todo e qualquer material impresso e/ou virtual, como
          sites, redes sociais, reportagens, vídeos institucionais, propaganda,
          outdoors, desenvolvidos pela EN FRANCE.
        </Typography>
        <Typography paragraph>
          Ciente do não recebimento de cachê, honorários, salário, ou pagamento
          de qualquer espécie, referente a confecção e veiculação de vídeo, sem
          que nada haja a ser reclamado a título de direitos conexos à imagem,
          indenizações ou a qualquer outro.
        </Typography>
        <Typography paragraph variant="h6" className={classes.bold}>
          28. Dúvidas
        </Typography>
        <Typography paragraph>
          Em caso de dúvida acerca do presente Termo de Acesso e Política de
          Privacidade, visite a página de perguntas e respostas ou acesse o chat
          disponível em nossa página.
        </Typography>
        <Typography paragraph>
          Todos os direitos não garantidos expressamente são reservados ao EN
          FRANCE
        </Typography>

        <Typography paragraph align="center">
          Termos de Uso - Atualizado em 19/07/2020
        </Typography>
      </div>
      <div className={classes.footer}>
        <Typography align="center">
          Copyright © 2020 - OFTB. All Rights Reserved |{" "}
          <a href="https://oftb.com.br/politica" target="_blank">
            Política de Privacidade
          </a>{" "}
          | <a href="mailto:suporte@osfrancesestomambanho.com.br"> Ajuda</a>
        </Typography>
        <Typography align="center">
          Q CLN 206, Bloco A, nº 6, Parte KK, Asa Norte, Brasília CEP:
          70.844-510
        </Typography>
      </div>
    </Grid>
  );
}
