import { Button, Grid, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/img/the-fluency-logo-nova.png";
import logoAlt from "../../assets/img/the-fluency-logo-nova.png";
import Loading from "../../components/Loading";

import { Creators } from "../../store/ducks/auth";
import useStyles from "./Login.style";

const Login = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const loading = useSelector((state) => state.auth.loading);

  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const authHandler = async () => {
    dispatch(Creators.signInRequest(loginValue, passwordValue));
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <img src={logoAlt} alt="logo" className={classes.mobileLogo} />
        <div className={classes.form}>
          <Typography variant="h1" className={classes.title}>
            Entrar
          </Typography>
          <Typography className={classes.greeting}>
            Bem-vindo(a), insira seu e-mail e senha para ter acesso à
            plataforma.
          </Typography>

          <TextField
            id="email"
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
            value={loginValue}
            onChange={(e) => setLoginValue(e.target.value)}
            margin="normal"
            placeholder="E-mail"
            type="email"
            fullWidth
          />

          <TextField
            id="password"
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            margin="normal"
            placeholder="Senha"
            type="password"
            fullWidth
          />

          <div className={classes.formButtons}>
            {loading ? (
              <Loading />
            ) : (
              <Button
                disabled={loginValue.length === 0 || passwordValue.length === 0}
                onClick={() => authHandler()}
                variant="contained"
                size="large"
                color="secondary"
              >
                <b>Entrar</b>
              </Button>
            )}
            <Link to="/recover-password" className={classes.forgetButton}>
              Esqueceu sua senha?
            </Link>
          </div>
          <div className={classes.termsOfUse}>
            <p>
              Ao fazer login você declara que esta ciente e concorda com os{" "}
              <a href="/termofuse" className={classes.termsOfUseLink}>
                termos de uso
              </a>{" "}
              da StartDigital.
            </p>
          </div>
        </div>
        <Typography className={classes.copyright}>
          © {moment().format("Y")} StartDigital. Todos os direitos reservados.
        </Typography>
      </div>
    </Grid>
  );
};

export default withRouter(Login);
