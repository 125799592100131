import styled from 'styled-components';
import { Grid, Button, Container as ContainerMUI } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import themePallet from '../../themes/default';
export const Container = styled(Grid)`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  flex: 1;
  background: ${themePallet.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const CardsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  section:hover {
    transition: 0.5s;
    background-color: #1b1b1b;
    border: 1px solid gray;
  }
`;

export const CardItem = styled.section`
  height: 200px;
  width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  background: #333;
  margin: 20px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: 80px;
    height: 80px;
    color: #fc0042 !important;
    margin-bottom: 10px;
  }

  h2 {
    color: white !important;
    font-size: x-large;
  }
`;

export const ImgDiv = styled(ContainerMUI)`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 100px;
  margin: 30px;
`;
