import React, { Component } from 'react';
import moment from "moment";
import Badge from '@material-ui/core/Badge';
import { withStyles } from "@material-ui/core/styles";
import http from "../../../../helpers/http";

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -40,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

export default class BadgeModulo extends Component {

    state = {
        completed: {},
        availableIn: {},
    }

    async componentDidMount() {

        const response = await http.get("api/student/subscriptions");
        const { data } = response;

        for (var i = 0; i < data.data[0]['modules'].length; i++){
            this.setState({ completed: data.data[0]['modules'][i]['completed_at'],
                            availableIn: data.data[0]['modules'][i]['available_in']
                        });
        }
    }

    render() {
        const { completed, availableIn } = this.state;
        
        const filter = (moment(availableIn).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && completed === null || completed === undefined);
        if (filter) {
            return (<StyledBadge badgeContent={'+1 Módulo'} color="primary">Meu Curso </StyledBadge>);   
        }
        else{
            return (<StyledBadge badgeContent={0} color="primary">Meu Curso </StyledBadge>);
        }
        
    }
}