import React from "react";
import { Router, Switch } from "react-router-dom";

import Route from "../components/Routes/components/Route";
import history from "../helpers/history";
import Login from "../pages/login/Login";
import Layout from "./Layout/Layout";

import TermOfUse from "../pages/termofuse/TermOfUse";

import "../assets/styles/global.scss";
import RecoverPassword from "../pages/recoverPassword/RecoverPassword";
import NewPassword from "../pages/recoverPassword/NewPassword";
import Dashboard from "../pages/dashboard/Dashboard";
import HomePage from "../pages/home/HomePage";

export default function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Login} isPrivate={false} />
        <Route
          exact
          path="/recover-password"
          component={RecoverPassword}
          isPrivate={false}
        />
        <Route
          exact
          path="/new-password"
          component={NewPassword}
          isPrivate={false}
        />
        <Route exact path="/home" component={HomePage} isPrivate={true} />

        <Route
          exact
          path="/termofuse"
          component={TermOfUse}
          isPrivate={false}
        />

        <Layout />
      </Switch>
    </Router>
  );
}
