import axios from "axios";
import { store } from "./../store";
import { Creators } from "../store/ducks/auth";
import { toast } from "react-toastify";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_PROD,
});
http.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error?.response?.data?.message === "Unauthenticated.") {
      toast.error("Sua sessão expirou.");
      store.dispatch(Creators.signOut());
    }
    return new Promise((resolve, reject) => {
      reject(error);
    });
  },
);
export default http;