import { Button, Card, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#03A64A",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  card: {
    margin: 20,
  },
  cardHeader: {
    fontWeight: "bold",
    padding: 15,
    borderBottom: "1px solid #EEE",
  },
  cardContent: {
    padding: 15,
  },
  submitButton: {
    marginTop: 10,
  },
}));

function NewPassword(props) {
  const query = queryString.parse(props.location.search);
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [
    newPasswordConfirmationValue,
    setNewPasswordConfirmationValue,
  ] = useState("");
  function handleSubmit() {
    if (!query.email || !query.token) {
      toast.error("Este link de recuperação não é válido.");
      return;
    }
    if (newPasswordValue !== newPasswordConfirmationValue) {
      toast.error("A senha de confirmação está incorreta");
      return;
    }
    http
      .post(`api/password/reset`, {
        token: query.token,
        email: query.email,
        password: newPasswordValue,
        password_confirmation: newPasswordConfirmationValue,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            response.data.message ||
              "We have emailed your password reset link!",
          );
          setRedirect(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          error.response.data.errors
            ? Object.entries(error.response.data.errors).forEach(([_, value]) =>
                toast.error(value[0]),
              )
            : toast.error(error.response.data.message);
        }
        if (!error.response) {
          toast.error("Erro no servidor, tente novamente mais tarde.");
        }
      });
  }
  if (redirect) {
    return <Redirect to="/" />;
  }
  return (
    <Grid container xs={12} className={classes.root}>
      <Card className={classes.card}>
        <Typography className={classes.cardHeader}>Atualizar Senha</Typography>
        <div className={classes.cardContent}>
          <Typography>Escolha uma nova senha para o seu usuário.</Typography>
          <TextField
            type="password"
            value={newPasswordValue}
            onChange={(e) => setNewPasswordValue(e.target.value)}
            margin="normal"
            placeholder="Nova senha"
            fullWidth
          />
          <TextField
            type="password"
            value={newPasswordConfirmationValue}
            onChange={(e) => setNewPasswordConfirmationValue(e.target.value)}
            margin="normal"
            placeholder="Confirmar Nova Senha"
            fullWidth
          />
          <Button
            className={classes.submitButton}
            disabled={
              newPasswordValue.length === 0 ||
              newPasswordConfirmationValue === 0
            }
            onClick={handleSubmit}
            variant="contained"
            size="large"
            color="secondary"
          >
            <b>Atualizar Senha</b>
          </Button>
        </div>
      </Card>
    </Grid>
  );
}

export default NewPassword;
