import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";

// components
import { Button } from "../../../../components/Wrappers";
import moment from "moment";

export default function TableComponent({ data }) {

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell key={1}>Salas</TableCell>
          <TableCell key={2}>Descrição Sala</TableCell>
          <TableCell key={3}>Ocupação</TableCell>
          <TableCell key={4}>Data</TableCell>
          <TableCell key={5}>Link</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length == 0 ?
          <Typography>Sem salas futuras</Typography> :
          data.map((c) => (
            <TableRow key={c.id}>
              <TableCell>{c.title}</TableCell>
              <TableCell>{c.description}</TableCell>
              <TableCell>{c.schedules.length}/{c.amount_students}</TableCell>
              <TableCell>{moment(c.schedule_time).format('DD/MM/YYYY HH:mm')}</TableCell>
              <TableCell>
                <Button
                  color={"success"}
                  size="small"
                  className="px-2"
                  variant="contained"
                  href={c.url}
                  target="_blank"
                >
                  Link
              </Button>
              </TableCell>
              {/* <TableCell>
              <Button
                color={"warning"}
                size="small"
                className="px-2"
                variant="contained"
              >
                Cancelar
              </Button>
            </TableCell> */}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
