import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  setCurrentClass: ["class", "typeClass"],
});

const INITIAL_STATE = {
  currentClass: {},
  typeCurrentClass: null,
};


export const setCurrentClass = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentClass: action.class,
    typeCurrentClass: action.typeClass,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_CLASS]: setCurrentClass,
});
