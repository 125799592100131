import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  cardTip: {
    minWidth: 350,
    minHeight: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  tipText: {
    padding: theme.spacing(4),
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 400,
    marginBottom: theme.spacing(4),
  },
  mobileLogo: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      width: 160,
      marginBottom: theme.spacing(4),
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    background: "white",
  },
  form: {
    width: 320,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  greeting: {
    textAlign: "center",
    fontSize: 15,
    color: "#666",
    margin: "0px auto 30px",
  },
  title: {
    fontWeight: 900,
    textAlign: "center",
    fontSize: 28,
    color: "#3c3c3c",
    margin: "0px auto 20px",
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textDecoration: "none",
    color: "black",
    textAlign: "center",
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
  termsOfUse: {
    textAlign: "center",
  },
  termsOfUseLink: {
    textDecoration: "none",
    color: "#F45527",
    fontWeight: "bold",
  },
}));
