import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  signInRequest: ["email", "password"],
  signInSuccess: ["access_token", "modules"],
  signFailure: [],
  getMeSuccess: ["user"],
  getMeFailure: [],
  signOut: [],
});

const INITIAL_STATE = {
  access_token: null,
  modules: [],
  user: {},
  signed: false,
  loading: false,
};

const signInRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};

const signInSuccess = (state = INITIAL_STATE, action) => {
  return {
    access_token: action.access_token,
    modules: action.modules,
    signed: true,
    loading: false,
  };
};

const signFailure = (state = INITIAL_STATE, action) => {
  return {
    loading: false,
  };
};

const getMeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    user: action.user,
  };
};

const signOut = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    access_token: null,
    signed: false,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_FAILURE]: signFailure,
  [Types.GET_ME_SUCCESS]: getMeSuccess,
  [Types.SIGN_OUT]: signOut,
});
