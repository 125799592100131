import React from "react";
import { Button, Box } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import PropTypes from "prop-types";

import useStyles from "./styles";

import { Typography } from "../Wrappers";
import history from "../../helpers/history";
import { Link } from "react-router-dom";

function PageTitle({ title, buttonName, buttonHref, breadcrumbs, ...rest }) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer} {...rest}>
      <div>
        <Typography
          className={classes.title}
          variant="h1"
          size="sm"
          style={{ fontWeight: 900, fontSize: 30, marginBottom: 5 }}
        >
          {title}
        </Typography>
        {breadcrumbs && (
          <Breadcrumbs>
            {breadcrumbs.map((bc) => {
              if (bc.title)
                return (
                  <Link className={classes.link} to={bc.path} key={bc}>
                    <Typography>{bc.title}</Typography>
                  </Link>
                );
              else return <Typography>{bc}</Typography>;
            })}
          </Breadcrumbs>
        )}
      </div>
      <Box mx={1} />
      {buttonName && buttonHref && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            history.push(buttonHref);
          }}
        >
          {buttonName}
        </Button>
      )}
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  buttonHref: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
};

export default PageTitle;
