import { combineReducers } from "redux";
import auth from "./auth";
import module from "./module";
import lesson from "./lesson";
import tutorClass from './tutorClass';

export default combineReducers({
  auth,
  module,
  lesson,
  tutorClass,
});
