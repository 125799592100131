import { makeStyles } from "@material-ui/styles";

const drawerWidth = 250;

export default makeStyles((theme) => ({
  logotype: {
    color: "white",
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  links: {
    textDecoration: "none",
  },
  drawerOpen: {
    width: drawerWidth,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
  },
  badgeColor: {
    background: "#F45527",
  },
  logoImg: {
    width: 70,
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  appBar: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
  },
  headerIconCollapse: {
    color: theme.palette.text.primary,
  },
  profileMenu: {
    minWidth: 265,
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 25,
    color: "#000000",
  },
  username: {
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  avatarImage: {
    marginRight: 15,
    background: "#000000",
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
