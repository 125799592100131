import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  SchoolOutlined,
  Public,
  Settings,
  AccountBalanceOutlined,
  EmojiPeople,
} from '@material-ui/icons';
import history from '../../helpers/history';

import { Creators } from '../../store/ducks/module';

import {
  Container,
  CardItem,
  ButtonAccess,
  Logo,
  CardsContainer,
  ImgDiv,
} from './styles';

import logo from '../../assets/img/the-fluency-logo-nova.png';

function Home() {
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.auth.modules);

  console.log(modules);

  if (modules.length === 1) {
    switch (modules[0]) {
      case 'student': {
        handleSelectModule('Student');
        return <Redirect to="/dashboard-student" />;
      }
      case 'tutor': {
        handleSelectModule('Tutor');
        return <Redirect to="/dashboard-tutor" />;
      }
      case 'admin': {
        handleSelectModule('Admin');
        return <Redirect to="/dashboard-admin" />;
      }
      case 'support': {
        handleSelectModule('Support');
        return <Redirect to="/dashboard-support" />;
      }
      case 'academic': {
        handleSelectModule('Academic');
        return <Redirect to="/academic/dashboard" />;
      }
      case 'partner': {
        handleSelectModule('Partner');
        return <Redirect to="/dashboard-partner" />;
      }
    }
  }

  function handleSelectModule(module) {
    dispatch(Creators.selectModule(module));

    switch (module) {
      case 'Student':
        history.push('/dashboard-student');
        break;
      case 'Admin':
        history.push('/dashboard-admin');
        break;
      case 'Support':
        history.push('/dashboard-support');
        break;
      case 'Tutor':
        history.push('/dashboard-tutor');
        break;
      case 'Academic':
        history.push('/academic/dashboard');
        break;
      case 'Partner':
        history.push('/dashboard-partner');
        break;
      default:
        break;
    }
  }

  return (
    <Container container>
      <CardsContainer>
        {!!modules.find((module) => module === 'Tutor') && (
          <CardItem onClick={() => handleSelectModule('Tutor')}>
            <div>
              <SchoolOutlined />
              <Typography variant="h2">TUTOR MODULE</Typography>
            </div>
          </CardItem>
        )}
        {!!modules.find((module) => module === 'Admin') && (
          <CardItem onClick={() => handleSelectModule('Admin')}>
            <div>
              <Public />
              <Typography variant="h2">
                ADMINISTRATIVE <br /> MODULE
              </Typography>
            </div>
          </CardItem>
        )}
      </CardsContainer>

      <ImgDiv className="imgDiv">
        <Logo src={logo} alt="" />
      </ImgDiv>
    </Container>
  );
}

export default Home;
