import { all, takeLatest, call, put } from "redux-saga/effects";
import { decode } from "jsonwebtoken";
import { toast } from "react-toastify";

import { Types, Creators } from "../ducks/auth";

import http from "../../helpers/http";
import history from "../../helpers/history";

export function* signIn(payload) {
  try {
    const { email, password } = payload;

    let timezone = 'America/Sao_paulo';
    
    const response = yield call(http.post, "/api/login", {
      email: email,
      password: password,
      timezone: timezone,
    });

    const { token, modules} = response.data.data;

    http.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(
      Creators.signInSuccess(
        token,
        modules,
      ),
    );
    history.push("/home");
  } catch (error) {
    toast.error("Usuário e/ou senhas inválidos!");
    yield put(Creators.signFailure());
  }
}

export function signOut(payload) {
  history.push("/");
}

export function setToken({ payload }) {
  if (!payload) {
    return;
  }
  const { access_token } = payload.auth;
  if (access_token) {
    http.defaults.headers.Authorization = `Bearer ${access_token}`;
  }
}

export function* getMe(payload) {
  try {
    const response = yield call(http.post, "/api/me");
    yield put(Creators.getMeSuccess(response.data.data));
  } catch (error) {
    toast.error("Usuário e/ou senhas inválidos!");
    yield put(Creators.signFailure());
  }
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
  takeLatest(Types.SIGN_IN_SUCCESS, getMe),
  takeLatest(Types.SIGN_OUT, signOut),
]);
