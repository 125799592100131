import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Avatar,
  Button,
  MenuItem
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Menu as MenuIcon, ArrowForward } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import history from "../../helpers/history";
import useStyles from "./styles";
import http from "../../helpers/http";

import { Typography } from "../Wrappers/Wrappers";

import logo from "../../assets/img/the-fluency-logo-nova.png";

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { Creators } from "../../store/ducks/auth";
import ChangePassword from "./ChangePassword";
import ChangeProfilePic from "./ChangeProfilePic";
import UploadPic from "./../UploadProfilePic/UploadPic";

export default function Header(props) {
  const dispatch = useDispatch();
  var classes = useStyles();
  const modules = useSelector((state) => state.auth.modules) || [];

  // global
  const { isSidebarOpened } = useLayoutState();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [profilePic, setProfilePic] = useState(null);

  // local
  // var [profileMenu, setProfileMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = useSelector((state) => state.auth.user);

  function handleSignOut() {
    dispatch(Creators.signOut());
  }

  function handleChangePassword() {
    setOpen(true);
  }

  function handleOpenModal() {
    setOpenModal(true);
  };

  async function handleLoadPicProfile() {
    const response = await http.post(`/api/me`);
    const { data } = response.data;
    
    setProfilePic(data['photo']);
  }

  useEffect(() => {
    handleLoadPicProfile();
  }, []);

  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: isSidebarOpened,
              [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
              }),
            }}
            open={isSidebarOpened}
          >
            <img src={logo} alt="logo" className={classes.logoImg} />
          </div>

          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButton,
              classes.headerMenuButtonCollapse,
            )}
          >
            {layoutState.isSidebarOpened ? (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            ) : (
                <ArrowForward
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              )}
          </IconButton>

          <div className={classes.grow} />

          <div className={classes.avatarContainer}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Avatar className={classes.avatarImage}>
                  <img src={`https://thefluency.s3.us-east-2.amazonaws.com/${profilePic}`}
                    style={{width: '100%', height: '100%', borderRadius: '50%'}}
                  />
              </Avatar>
              <Typography className={classes.username}>
                <b>{user ? user.name : ""}</b>
              </Typography>
            </Button>
          </div>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
          >
            {modules.length > 1 ? (
              <MenuItem className={classes.profileMenuItem}>
                <Typography
                  className={classes.profileMenuLink}
                  onClick={(e) => history.push('/home')}
                >
                  Change module
                </Typography>
              </MenuItem>
            ) : null}
            <MenuItem>
              <Typography
                className={classes.profileMenuLink}
                onClick={handleChangePassword}
              >
                Alterar Senha
            </Typography>
            </MenuItem>

            <MenuItem>
              <Typography
                className={classes.profileMenuLink}
                onClick={handleOpenModal}
              >
                Alterar Foto do Perfil
              </Typography>
            </MenuItem>

            <MenuItem>
              <Typography
                className={classes.profileMenuLink}
                onClick={handleSignOut}
              >
                Sair
            </Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <ChangePassword open={open} setOpen={setOpen} />

      <ChangeProfilePic openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
