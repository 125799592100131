import { lazy } from "react";
import Classrooms from "./classroom/Classrooms";

import ClassroomsAdminReplay from "./classroom/ClassroomsAdminReplay";
import AgendaAdmin from "./agendaAdmin/AgendaAdmin";
// import FormPathway from "./pathways/NewPathway";

const Tag = lazy(() => import("./tags/Tags"));
const NewTag = lazy(() => import("./tags/NewTag"));

const Student = lazy(() => import("./students/Student"));
const NewStudent = lazy(() => import("./students/NewStudent"));
const EditStudent = lazy(() => import("./students/EditStudent"));;

const Materials = lazy(() => import("./materials/Materials"));
const NewMaterial = lazy(() => import("./materials/NewMaterial"));
const EditMaterial = lazy(() => import("./materials/EditMaterial"));

const NewModule = lazy(() => import("./modules/NewModule"));
const Modules = lazy(() => import("./modules/Modules"));
const EditModule = lazy(() => import("./modules/EditModule"));

const NewVideo = lazy(() => import("./videos/NewVideo"));
const EditVideo = lazy(() => import("./videos/EditVideo"));
const Videos = lazy(() => import("./videos/Videos"));

const NewBanner = lazy(() => import("./banners/NewBanner"));
const EditBanner = lazy(() => import("./banners/EditBanner"));
const Banners = lazy(() => import("./banners/Banners"));

const NewPathway = lazy(() => import("./pathways/NewPathway"));
const EditPathway = lazy(() => import("./pathways/EditPathway"));
const Pathways = lazy(() => import("./pathways/Pathways"));

const NewLanguage = lazy(() => import("./languages/NewLanguage"));
const EditLanguage = lazy(() => import("./languages/EditLanguage"));
const Languages = lazy(() => import("./languages/Languages"));

const NewLesson = lazy(() => import("./lessons/NewLesson"));
const EditLesson = lazy(() => import("./lessons/EditLesson"));
const Lessons = lazy(() => import("./lessons/Lessons"));

const NewCourse = lazy(() => import("./courses/NewCourse"));
const EditCourse = lazy(() => import("./courses/EditCourse"));
const Courses = lazy(() => import("./courses/Courses"));


const NewEbook = lazy(() => import("./ebooks/NewEbook"));
const EditEbook = lazy(() => import("./ebooks/EditEbook"));
const Ebooks = lazy(() => import("./ebooks/Ebooks"));


const NewLive = lazy(() => import("./lives/NewLive"));
const EditLive = lazy(() => import("./lives/EditLive"));
const Lives = lazy(() => import("./lives/Lives"));


const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Faq = lazy(() => import("./external/Faq"));
const Support = lazy(() => import("./external/Support"));

const ConversationClasses = lazy(() =>
  import("./conversationClasses/ConversationClasses"),
);
const EditConversationClass = lazy(() =>
  import("./conversationClasses/EditConversationClass"),
);
const NewConversationClass = lazy(() =>
  import("./conversationClasses/NewConversationClass"),
);

const StudentsCourse = lazy(() => import("./studentsCourse/StudentsCourse"));
const CopyModule = lazy(() => import("./modules/CopyModule"));

const GroupClass = lazy(() => import('./classes/groupclass/GroupClass'));
const NewGroupClass = lazy(() => import('./classes/groupclass/NewGroupClass'));
const EditGroupClass = lazy(() =>import('./classes/groupclass/EditGroupClass'));


const MasterClass = lazy(() => import('./classes/masterclass/MasterClass'));
const NewMasterClass = lazy(() => import('./classes/masterclass/NewMasterClass'));
const EditMasterClass = lazy(() =>import('./classes/masterclass/EditMasterClass'));

const NewSchedule = lazy(() =>import('./schedules/new-schedule/NewSchedule'));
const Schedules = lazy(() =>import('./schedules/Schedules'));
const EditSchedule = lazy(() =>import('./schedules/EditSchedule'));

const HomePage = lazy(() =>import('./home/HomePage'));
const DashboardTutor = lazy(() => import('./dashboard/DashboardTutor'));
const AgendaTutor = lazy(() => import('./agendaTutor/AgendaTutor'));
const ClassDetails = lazy(() => import('./classDetails/ClassDetails'));
const TutorHistoric = lazy(() => import('./tutorHistoric'));

export const routes = [
  {
    exact: false,
    private: true,
    path: "/home",
    component: HomePage,
  },
  {
    exact: false,
    private: true,
    path: "/dashboard",
    component: Dashboard,
  },

  {
    exact: false,
    private: true,
    path: '/dashboard-tutor',
    component: DashboardTutor,
  },
  {
    exact: false,
    private: true,
    path: '/classrooms-tutor',
    component: AgendaTutor,
  },
  {
    exact: false,
    private: true,
    path: '/historic',
    component: TutorHistoric,
  },
  {
    exact: false,
    private: true,
    path: '/class-details',
    component: ClassDetails,
  },
  {
    exact: false,
    private: true,
    path: "/new-student",
    component: NewStudent,
  },
  {
    exact: false,
    private: true,
    path: "/students",
    component: Student,
  },
  {
    exact: false,
    private: true,
    path: "/student/:id",
    component: EditStudent,
  },
  {
    exact: false,
    private: true,
    path: "/tags",
    component: Tag,
  },
  {
    exact: false,
    private: true,
    path: "/new-tag",
    component: NewTag,
  },
  {
    exact: false,
    private: true,
    path: "/modules",
    component: Modules,
  },
  {
    exact: false,
    private: true,
    path: "/new-module",
    component: NewModule,
  },
  {
    exact: false,
    private: true,
    path: "/module/:id",
    component: EditModule,
  },
  {
    exact: false,
    private: true,
    path: "/copy-module/:id",
    component: CopyModule,
  },
  {
    exact: true,
    private: true,
    path: "/lessons",
    component: Lessons,
  },
  {
    exact: true,
    private: true,
    path: "/lessons/:id/new'",
    component: NewLesson,
  },
  {
    exact: true,
    private: true,
    path: '/lessons/:id/new',
    component: NewLesson,
  },
  {
    exact: false,
    private: true,
    path: "/lesson/:id",
    component: EditLesson,
  },
  {
    exact: false,
    private: true,
    path: "/new-banner",
    component: NewBanner,
  },
  {
    exact: false,
    private: true,
    path: "/banner/:id",
    component: EditBanner,
  },
  {
    exact: false,
    private: true,
    path: "/banners",
    component: Banners,
  },
  {
    exact: false,
    private: true,
    path: "/videos",
    component: Videos,
  },
  {
    exact: false,
    private: true,
    path: "/new-video",
    component: NewVideo,
  },
  {
    exact: false,
    private: true,
    path: "/video/:id",
    component: EditVideo,
  },
  {
    exact: false,
    private: true,
    path: "/pathways",
    component: Pathways,
  },
  {
    exact: false,
    private: true,
    path: "/new-pathway",
    component: NewPathway,
  },
  {
    exact: false,
    private: true,
    path: "/pathway/:id",
    component: EditPathway,
  },
  {
    exact: false,
    private: true,
    path: "/languages",
    component: Languages,
  },
  {
    exact: false,
    private: true,
    path: "/new-language",
    component: NewLanguage,
  },
  {
    exact: false,
    private: true,
    path: "/language/:id",
    component: EditLanguage,
  },
  {
    exact: false,
    private: true,
    path: "/courses",
    component: Courses,
  },
  {
    exact: true,
    private: true,
    path: "/new-course",
    component: NewCourse,
  },
  {
    exact: true,
    private: true,
    path: "/new-course/:id",
    component: NewCourse,
  },
  {
    exact: false,
    private: true,
    path: "/course/:id",
    component: EditCourse,
  },
  {
    exact: false,
    private: true,
    path: "/materials",
    component: Materials,
  },
  {
    exact: false,
    private: true,
    path: "/new-material",
    component: NewMaterial,
  },
  {
    exact: false,
    private: true,
    path: "/material/:id",
    component: EditMaterial,
  },
  {
    exact: false,
    private: true,
    path: "/conversations",
    component: ConversationClasses,
  },
  {
    exact: false,
    private: true,
    path: "/new-conversation",
    component: NewConversationClass,
  },
  {
    exact: false,
    private: true,
    path: "/conversation/:id",
    component: EditConversationClass,
  },

  {
    exact: false,
    private: true,
    path: "/ebooks",
    component: Ebooks,
  },
  {
    exact: false,
    private: true,
    path: "/new-ebook",
    component: NewEbook,
  },
  {
    exact: false,
    private: true,
    path: "/ebook/:id",
    component: EditEbook,
  },

  {
    exact: false,
    private: true,
    path: "/lives",
    component: Lives,
  },
  {
    exact: false,
    private: true,
    path: "/new-live",
    component: NewLive,
  },
  {
    exact: false,
    private: true,
    path: "/live/:id",
    component: EditLive,
  },

  {
    exact: false,
    private: true,
    path: "/support",
    component: Support,
  },
  {
    exact: false,
    private: true,
    path: "/student-course",
    component: StudentsCourse,
  },
  {
    exact: false,
    private: true,
    path: "/faq",
    component: Faq,
  },
  {
    exact: false,
    private: true,
    path: "/classrooms",
    component: Classrooms,
  },
  {
    exact: true,
    private: true,
    path: "/classrooms-admin-replay",
    component: ClassroomsAdminReplay,
  },
  /*{
    exact: false,
    private: true,
    path: "/schedule",
    component: AgendaAdmin,
  },*/

  {
    exact: true,
    private: true,
    path: '/groupClass',
    component: GroupClass,
  },
  {
    exact: true,
    private: true,
    path: '/groupClass/new',
    component: NewGroupClass,
  },
  {
    exact: false,
    private: true,
    path: '/groupClass/:id',
    component: EditGroupClass,
  },

  {
    exact: true,
    private: true,
    path: '/masterclass',
    component: MasterClass,
  },
  {
    exact: true,
    private: true,
    path: '/masterclass/new',
    component: NewMasterClass,
  },
  {
    exact: false,
    private: true,
    path: '/masterclass/:id',
    component: EditMasterClass,
  },


  {
    exact: false,
    private: true,
    path: '/new-schedule',
    component: NewSchedule,
  },
  {
    exact: false,
    private: true,
    path: '/schedules',
    component: Schedules,
  },
  {
    exact: false,
    private: true,
    path: '/schedule/:id',
    component: EditSchedule,
  },
];
