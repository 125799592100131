import React, { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Card,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import http from "../../helpers/http";
import history from "../../helpers/history";

import Loading from "../../components/Loading";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { objectToQueryParams } from "../../helpers/objectQueryParams";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  card: {
    borderBottomWidth: 0,
    // height: 400,
  },
  headerCard: {
    textAlign: "center",
    backgroundColor: "rgba(255, 145, 39, 0.3)",
    paddingTop: 12,
    paddingBottom: 12,
  },
  titleHeader: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
  },
  subtitleHeader: {
    color: theme.palette.text.secondary,
    fontSize: 13,
  },
  availableSchedule: {
    textAlign: "center",
    padding: 12,
    borderTopWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderStyle: "solid",
  },
  titleSchedule: {
    fontSize: 16,
    fontWeight: "bold",
  },
  hourSchedule: {
    fontSize: 13,
  },
  vacationSchedule: {
    fontSize: 10,
  },
  topSchedule: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  grid: {
    marginBottom: 16,
  },
  arrowButton: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
  },
}));

const getWeekDayDescription = (date) => {
  const days = {
    0: "Domingo",
    1: "Segunda",
    2: "Terça",
    3: "Quarta",
    4: "Quinta",
    5: "Sexta",
    6: "Sábado",
  };

  return days[moment(date).weekday()];
};

const isToday = (date) => {
  return moment(date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD");
};

const getFormattedDay = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

const getFormattedTime = (date) => {
  return moment(date).format("HH:mm");
};

function AgendaAdmin() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(6, "days"));
  const [calendar, setCalendar] = useState({});
  const [selectedClass, setSelectedClass] = useState(null);

  async function loadConversationClasses() {
    try {
      setLoading(true);

      let calendarStub = {};

      for (var i = 0; i <= 6; i++) {
        var date = startDate.clone().add(i, "day").format("YYYY-MM-DD");

        if (calendarStub[date] === undefined) calendarStub[date] = new Array();
      }

      const dates = objectToQueryParams({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
        return_type: "collection",
      });
      const response = await http.get(
        `/api/admin/conversationClasses?${dates}`,
      );

      const { data } = response.data;

      data.forEach((item) => {
        var dateSchedule = moment(item.schedule_time).format("YYYY-MM-DD");

        if (calendarStub[dateSchedule] === undefined)
          calendarStub[dateSchedule] = new Array();

        calendarStub[dateSchedule].push(item);
      });

      setCalendar(calendarStub);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error to load the Conversation Classes");
      history.push("/");
    }
  }

  useEffect(() => {
    loadConversationClasses();
  }, []);

  const loadNextWeek = () => {
    startDate.add(7, "day");
    endDate.add(7, "day");

    loadConversationClasses();
  };

  const loadPreviousWeek = () => {
    if (!isToday(startDate)) {
      startDate.subtract(7, "day");
      endDate.subtract(7, "day");
    }

    loadConversationClasses();
  };

  const handleClickOpen = (conversationClass) => {
    setSelectedClass(conversationClass);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedClass(null);
    setOpen(false);
  };

  async function cancelClass() {
    try {
      await http.delete(`/api/admin/conversationClasses/${selectedClass.id}`);
      toast.success("Encontro cancelado com sucesso!");
      setOpen(false);
    } catch (error) {
      toast.error("Falha ao cancelar encontro.");
    }
  }

  const breadcrumbs = [
		{ title: "Home", path: "/dashboard" },
		{ title: "Agenda", path: "/schedule" },
	  ];

  return (
    <>
      <PageTitle title="Agenda" breadcrumbs={breadcrumbs} />
      <Grid container>
        <Grid item xs={12} className={classes.arrowButton}>
          <Button
            variant="outlined"
            color="primary"
            disabled={isToday(startDate)}
            className={classes.button}
            onClick={() => loadPreviousWeek()}
            startIcon={<NavigateBeforeIcon />}
          >
            Anterior
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => loadNextWeek()}
            endIcon={<NavigateNextIcon />}
          >
            Próximo
          </Button>
        </Grid>

        {loading ? (
          <Loading />
        ) : (
          <>
            {Object.keys(calendar).map((day) => {
              if (moment(day).weekday() != 0)
                return (
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card className={classes.card}>
                      <div className={classes.headerCard}>
                        <Typography className={classes.titleHeader}>
                          {getWeekDayDescription(day)}
                        </Typography>
                        <Typography className={classes.subtitleHeader}>
                          {getFormattedDay(day)}
                        </Typography>
                      </div>
                      {calendar[day].length > 0 ? (
                        calendar[day].map((value) => {
                          return (
                            <div className={classes.availableSchedule}>
                              <Typography className={classes.hourSchedule}>
                                {moment(value.schedule_time).format("HH:mm")}
                              </Typography>
                              <Typography className={classes.titleSchedule}>
                                {value.title}
                              </Typography>
                              <Typography className={classes.vacationSchedule}>
                                {value.schedules.length} MATRÍCULA(S)
                              </Typography>
                              <Typography className={classes.vacationSchedule}>
                                {value.amount_students - value.schedules.length}{" "}
                                VAGA(S)
                              </Typography>

                              <Box mt={1} />

                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleClickOpen(value)}
                              >
                                DETALHES
                              </Button>
                            </div>
                          );
                        })
                      ) : (
                        <div className={classes.availableSchedule}>
                          <Typography className={classes.hourSchedule}>
                            Sem aulas agendadas
                          </Typography>
                        </div>
                      )}
                    </Card>
                  </Grid>
                );
            })}
          </>
        )}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {selectedClass && (
          <DialogTitle id="alert-dialog-title">
            {selectedClass.title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedClass && (
              <div>
                <Typography>
                  Alunos:{" "}
                  {selectedClass.schedules.length == 0 ? "Sem matriculas" : ""}
                </Typography>
                {selectedClass.schedules.map((s) => (
                  <Typography className={classes.vacationSchedule}>
                    {s.student.user.name}
                  </Typography>
                ))}
                <Typography>
                  Horário: {getFormattedDay(selectedClass.schedule_time)}{" "}
                  {getFormattedTime(selectedClass.schedule_time)}
                </Typography>

                <Typography>
                  <Button onClick={cancelClass} color="danger">
                    Cancelar encontro
                  </Button>
                </Typography>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button onClick={handleClose} color="secondary">
            SAIR
          </Button>
          {selectedClass && (
            <>
            {selectedClass.url &&(
            <Button href={selectedClass.url} target="_blank" color="primary">
              Ir para o encontro
            </Button>
          )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AgendaAdmin;
