import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Loading from "../Loading";
import { toast } from "react-toastify";
import http from "../../helpers/http";
import './styles.css';
import { CameraAlt as IconCamera } from "@material-ui/icons";



function UploadPic() {
  const [profilePic, setProfilePic] = useState();
  const [loading, setLoading] = useState(false);

  async function handleLoadPicProfile() {
    const response = await http.post(`/api/me`);
    const { data } = response.data;
    
    setProfilePic(data['photo']);
  }

  useEffect(() => {
    handleLoadPicProfile();
  }, []);

  async function handleProfilePicUpdate(event) {
    const files = event.target.files;
    const formData = new FormData();

    formData.append('file', files[0]);
    
    setLoading(true);

    const response = await http.post('/api/photo', 
          formData).then((response) => {
            toast.success("Foto do Perfil Atualizada com Sucesso!");
            // const reader = new FileReader();
            //   reader.onload = () => {
            //     if (reader.readyState === 2) {
            //       setProfilePic(reader.result);
            //     }
            //   };
            //   reader.readAsDataURL(files[0]);
              setProfilePic(response.data.data.photo);
        });
    setLoading(false);
  }


  return(
      <div>

          {loading ? (
              <Loading />
          ): (  
            <div className="ProfilePic">
                <img src={`https://thefluency.s3.us-east-2.amazonaws.com//${profilePic}`} />
                <label htmlFor="file">
                  <IconCamera />

                  <input
                    type="file"
                    style={{display: 'none'}}
                    accept="image/*"
                    name="photo"
                    id="file"
                    onChange={handleProfilePicUpdate}
                  />
                </label>
            </div>
          )}
      </div>
  )
}

export default UploadPic;



