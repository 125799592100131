import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: "soulphiaLms",
      storage,
      whitelist: ["auth", "module", "tutorClass"],
    },
    reducers,
  );
  return persistedReducer;
};