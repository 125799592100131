import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  selectModule: ["module"],
});

const INITIAL_STATE = {
  module: null,
};

const selectModule = (state = INITIAL_STATE, action) => {
  return {
    module: action.module,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.SELECT_MODULE]: selectModule,
});
