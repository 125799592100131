import { Button, Card, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import http from "../../helpers/http";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#03A64A",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  card: {
    margin: 20,
  },
  cardHeader: {
    fontWeight: "bold",
    padding: 15,
    borderBottom: "1px solid #EEE",
  },
  cardContent: {
    padding: 15,
  },
  submitButton: {
    marginTop: 10,
  },
}));

function RecoverPassword() {
  const classes = useStyles();
  const [emailValue, setEmailValue] = useState("");
  function handleSubmit() {
    http
      .post(`api/password/email`, {
        email: emailValue,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            response.data.message ||
              "Enviámos por e-mail o link de redefinição de senha!",
          );
          setEmailValue("");
        }
      })
      .catch((error) => {
        if (error.response) {
          error.response.data.errors
            ? Object.entries(error.response.data.errors).forEach(([_, value]) =>
                toast.error(value[0]),
              )
            : toast.error(error.response.data.message);
        }
        if (!error.response) {
          toast.error("Erro no servidor, tente novamente mais tarde.");
        }
      });
  }
  return (
    <Grid container xs={12} className={classes.root}>
      <Card className={classes.card}>
        <Typography className={classes.cardHeader}>Recuperar Senha</Typography>
        <div className={classes.cardContent}>
          <Typography>
            Um link será enviado para o seu email contendo as instruções para
            definir uma nova senha.
          </Typography>
          <TextField
            id="email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            margin="normal"
            placeholder="E-mail"
            type="email"
            fullWidth
          />
          <Button
            className={classes.submitButton}
            disabled={emailValue.length === 0}
            onClick={handleSubmit}
            variant="contained"
            size="large"
            color="secondary"
          >
            <b>Enviar Instruções</b>
          </Button>
        </div>
      </Card>
    </Grid>
  );
}

export default RecoverPassword;
